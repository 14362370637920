<template>
  <div @mouseover="onOver" @mouseleave="onLeave" class="nav-link">
    <b-dropdown
      split
      ref="dropdown"
      target="_blank"
      class="p-0 m-0 cusomer-drop"
      variant="none"
      @click="check()"
    >
      <template #button-content>
        <b-nav-item>
          <slot name="name"></slot>
        </b-nav-item>
      </template>
      <slot name="content-show"></slot>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: '',
    },
  },
  name: 'b-dropdown-hover',
  methods: {
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
    check() {
      this.$router.push(this.link);
    },
  },
};
</script>
<style lang="scss">
.cusomer-drop {
  display: flex !important;
  flex-direction: rows;
  .btn {
    padding: 0;
    margin: 0;
    display: flex !important;
    flex-direction: rows;
    align-items: center;
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
